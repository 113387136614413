import { reactive, toRefs } from "vue";

import store from "@/store/index";
import api from "@/services/api";

export function userCan(permission) {
  return store.getters["auth/userCan"](permission);
}

const state = reactive({
  listPermission: [],
  listPermissionLoading: false,
});

export function usePermission() {
  const getListPermission = () => {
    state.listPermissionLoading = true;

    return new Promise((resolve, reject) => {
      api
        .get("/role/permission")
        .then((res) => {
          const {
            data: { result },
          } = res;

          state.listPermission = result;
          resolve(result);
        })
        .catch((err) => reject(err))
        .finally(() => {
          state.listPermissionLoading = false;
        });
    });
  };

  return {
    ...toRefs(state),
    getListPermission,
  };
}
