const crmRoutes = [
  {
    path: "/team",
    meta: {
      hasChild: true,
    },
    component: () =>
      import(/* webpackChunkName: "team" */ "../views/team/TeamBase.vue"),
    children: [
      {
        path: "",
        name: "team/list",
        meta: {
          menuName: "Team",
          permission: "read.team",
        },
        component: () =>
          import(/* webpackChunkName: "team" */ "../views/team/TeamList.vue"),
      },
      {
        path: "/team/create",
        name: "team/create",
        meta: {
          permission: "create.team",
        },
        component: () =>
          import(/* webpackChunkName: "team" */ "../views/team/TeamForm.vue"),
      },
      {
        path: "/team/:uuid",
        name: "team/show",
        meta: {
          permission: "update.team",
        },
        component: () =>
          import(/* webpackChunkName: "team" */ "../views/team/TeamForm.vue"),
      },
    ],
  },
  {
    path: "/event",
    meta: {
      hasChild: true,
    },
    component: () =>
      import(/* webpackChunkName: "event" */ "../views/event/EventBase.vue"),
    children: [
      {
        path: "",
        name: "event/list",
        meta: {
          menuName: "Event",
          permission: "read.event",
        },
        component: () =>
          import(
            /* webpackChunkName: "event" */ "../views/event/EventList.vue"
          ),
      },
      {
        path: "/event/create",
        name: "event/create",
        meta: {
          permission: "create.event",
        },
        component: () =>
          import(
            /* webpackChunkName: "event" */ "../views/event/EventForm.vue"
          ),
      },
      {
        path: "/event/:uuid",
        name: "event/show",
        meta: {
          permission: "update.event",
        },
        component: () =>
          import(
            /* webpackChunkName: "event" */ "../views/event/EventForm.vue"
          ),
      },
    ],
  },
  {
    path: "/member-crm",
    name: "member-crm/list",
    meta: {
      menuName: "Member",
      permission: "read.member_crm",
    },
    component: () =>
      import(/* webpackChunkName: "crm" */ "../views/crm/CrmMember.vue"),
  },
  {
    path: "/member-crm/:uuid",
    name: "member-crm/show",
    meta: {
      permission: "read.member_crm",
    },
    component: () =>
      import(/* webpackChunkName: "crm" */ "../views/crm/CrmMemberForm.vue"),
  },
  {
    path: "/call",
    name: "call",
    meta: {
      menuName: "New Call",
      permission: "get.call",
      counterKey: "call",
    },
    component: () =>
      import(/* webpackChunkName: "call" */ "../views/crm/CrmCall.vue"),
  },
  {
    path: "/callback",
    name: "callback",
    meta: {
      menuName: "Callback",
      permission: "get.call",
      counterKey: "callback",
    },
    component: () =>
      import(/* webpackChunkName: "call" */ "../views/crm/CrmCall.vue"),
  },
  {
    path: "/call-event",
    meta: {
      hasChild: true,
    },
    component: () =>
      import(/* webpackChunkName: "call" */ "../views/event/EventBase.vue"),
    children: [
      {
        path: "",
        name: "call-event/list",
        meta: {
          menuName: "Event",
          permission: "get.call",
          counterKey: "event",
        },
        component: () =>
          import(
            /* webpackChunkName: "call" */ "../views/event/EventListTele.vue"
          ),
      },
      {
        path: "/call-event/:uuid",
        name: "call-event",
        meta: {
          permission: "get.call",
          title: "Event Call",
        },
        component: () =>
          import(/* webpackChunkName: "call" */ "../views/crm/CrmCall.vue"),
      },
    ],
  },
  {
    path: "/member-crm/call-log",
    name: "member-crm/call-log",
    meta: {
      menuName: "Call Log",
      permission: "call_log.member_crm",
    },
    component: () =>
      import(
        /* webpackChunkName: "crm-log" */ "../views/crm/CrmMemberCallLog.vue"
      ),
  },
  {
    path: "/member-crm/call-summary",
    name: "member-crm/call-summary",
    meta: {
      menuName: "Call Summary",
      permission: "call_summary.member_crm",
    },
    component: () =>
      import(
        /* webpackChunkName: "crm-log" */ "../views/crm/CrmMemberCallSummary.vue"
      ),
  },
  {
    path: "/member-crm/import",
    name: "member-crm/import",
    meta: {
      menuName: "Import Member",
      permission: "read.member_crm_import",
    },
    component: () => import("../views/crm/CrmMemberImport.vue"),
  },
];

export default crmRoutes;
