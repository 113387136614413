import { ref } from "vue";
import Swal from "sweetalert2";
import isObject from "lodash/isObject";
import { listDateRangeField } from "@/use/date";
import useClipboard from "vue-clipboard3";

export const toast = Swal.mixin({
  toast: true,
  position: "bottom-end",
  showConfirmButton: false,
  timer: 5000,
  timerProgressBar: true,
});

export const pageTitle = ref("Please wait..");

export function scrollToTitle() {
  document.getElementById("page-title").scrollIntoView();
}

export function parseBadgeCounter(val = null) {
  if (val === null || Number(val) <= 0) {
    return null;
  } else {
    return `${val}`;
  }
}

export function copyToClipboard(text, message = null) {
  const { toClipboard } = useClipboard();

  toClipboard(text).then(async () => {
    await toast.fire({
      icon: "success",
      text: message,
      timer: 3000,
      position: "top-right",
    });
  });
}

export function setPageTitle(title = null) {
  document.title = title;
  pageTitle.value = title;
}

export function convertToNumber(val) {
  return Number(val);
}

export const sortDir = [
  {
    id: "asc",
    name: "Ascending (A-Z)",
  },
  {
    id: "desc",
    name: "Descending (Z-A)",
  },
];

export function cleanQueryParams(data) {
  Object.keys(data).forEach((key) => {
    if (!data[key]) {
      delete data[key];
    } else if (isObject(data[key])) {
      if (listDateRangeField.includes(key)) {
        if (data[key]["start"] !== null && data[key]["end"] !== null) {
          data[key] = `${data[key]["start"]}-${data[key]["end"]}`;
        } else {
          delete data[key];
        }
      } else {
        data[key] = data[key]["id"];
      }
    }
  });

  return data;
}

export function cleanupEmptyObjectValues(objectData = {}) {
  Object.keys(objectData).forEach((key) => {
    if (!objectData[key]) {
      delete objectData[key];
    }

    if (isObject(objectData[key])) {
      if (key === "status") {
        objectData[key] = objectData[key]["id"];
      } else {
        objectData[`${key}_id`] = objectData[key]["id"];
        delete objectData[key];
      }
    }
  });

  return objectData;
}

export function convertObjectValuesToInteger(
  objectData = {},
  objectKeysToInteger = []
) {
  const keys = ["page", "limit", "total", ...objectKeysToInteger];

  Object.keys(objectData).forEach((key) => {
    if (keys.includes(key)) {
      objectData[key] = parseInt(objectData[key]);
    }
  });

  return objectData;
}

export function composeQueryString(query = {}) {
  let queryString = "";

  Object.keys(query).forEach((key, index) => {
    const prefix = index === 0 ? "?" : "&";

    queryString += `${prefix}${key}=${query[key]}`;
  });

  return queryString;
}

export function apiConfig(id = null) {
  const apiMethod = id ? "patch" : "post";

  return {
    apiMethod,
  };
}

export function randomNumber(min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

export function toTitleCase(str) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}
