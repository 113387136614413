<script setup>
import { ref, computed, watch } from "vue";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import { userCan } from "@/use/permission";
import { routes } from "@/router";
import { has } from "lodash";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { parseBadgeCounter } from "@/use/helper";

import { useMenuCounter } from "@/use/menuCounter.js";

const { updateMenuCounter, menuCounter } = useMenuCounter();
const listRoute = [];

routes.forEach((route) => {
  if (has(route, "meta.hasChild")) {
    const hasDropdown = has(route, "meta.hasDropdown");

    if (hasDropdown) {
      const subMenu = [];

      route["children"].forEach((child) => {
        const {
          meta: { menuName, permission },
          name: routeName,
        } = child;

        subMenu.push({
          name: menuName,
          permission: permission,
          route: routeName,
          meta: child.meta,
        });
      });

      const {
        meta: { dropdownName, permission },
      } = route;

      listRoute.push({
        name: dropdownName,
        permission: permission,
        children: subMenu,
        meta: route.meta,
      });
    } else {
      route["children"].forEach((child) => {
        if (has(child, "meta.menuName")) {
          const {
            meta: { menuName, permission },
            name: routeName,
          } = child;

          listRoute.push({
            name: menuName,
            permission: permission,
            route: routeName,
            meta: child.meta,
          });
        }
      });
    }
  } else {
    // Parent menu
    if (has(route, "meta.menuName")) {
      const {
        meta: { menuName, permission },
        name: routeName,
      } = route;

      listRoute.push({
        name: menuName,
        permission: permission,
        route: routeName,
        meta: route.meta,
      });
    }
  }
});

listRoute.sort((a, b) => {
  return a["name"].localeCompare(b["name"]);
});

import {
  Dialog,
  DialogOverlay,
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  // Menu,
  // MenuButton,
  // MenuItem,
  // MenuItems,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import { MenuAlt2Icon, XIcon, UserCircleIcon } from "@heroicons/vue/outline";
import { SearchIcon } from "@heroicons/vue/solid";

import Swal from "sweetalert2";

let sidebarOpen = ref(false);
const store = useStore();
const router = useRouter();
const route = useRoute();

const isLoggedIn = computed(() => store.getters["auth/isLoggedIn"]);
const authUsername = computed(() => store.getters["auth/userInfo"]("username"));
const authUserGroup = computed(() => store.getters["auth/userInfo"]("group"));
const authUserRole = computed(() => store.getters["auth/userInfo"]("role"));

const serverVersion = computed(
  () => store.getters["versioning/currentServerVersion"]
);

const appVersion = computed(
  () => store.getters["versioning/currentAppVersion"]
);

const invalidVersion = computed(
  () => store.getters["versioning/invalidVersion"]
);

const navigation = computed(() => {
  return listRoute.filter((route) => {
    if (userCan(route.permission)) {
      return route;
    }
  });
});

function signOut() {
  store.dispatch("auth/logout").finally(() => {
    store.commit("auth/destroyUserInfo");
    store.commit("auth/destroyPermission");
    store.commit("auth/destroyToken");
    router.push({ name: "auth/login" }).then(() => {
      sidebarOpen.value = false;
      Swal.fire({
        title: "You have been logged out.",
        icon: "success",
      });
    });
  });
}

watch(route, async () => {
  sidebarOpen.value = false;

  if (isLoggedIn.value) {
    updateMenuCounter();
  }
});
</script>

<template>
  <div class="h-screen flex overflow-hidden bg-gray-100">
    <TransitionRoot as="template" :show="sidebarOpen">
      <Dialog
        as="div"
        class="fixed inset-0 flex z-40 lg:hidden"
        @close="sidebarOpen = false"
      >
        <TransitionChild
          as="template"
          enter="transition-opacity ease-linear duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <DialogOverlay class="fixed inset-0 bg-gray-600 bg-opacity-75" />
        </TransitionChild>
        <TransitionChild
          as="template"
          enter="transition ease-in-out duration-300 transform"
          enter-from="-translate-x-full"
          enter-to="translate-x-0"
          leave="transition ease-in-out duration-300 transform"
          leave-from="translate-x-0"
          leave-to="-translate-x-full"
        >
          <div
            class="relative flex-1 flex flex-col max-w-xs w-full bg-gray-800"
          >
            <TransitionChild
              as="template"
              enter="ease-in-out duration-300"
              enter-from="opacity-0"
              enter-to="opacity-100"
              leave="ease-in-out duration-300"
              leave-from="opacity-100"
              leave-to="opacity-0"
            >
              <div class="absolute top-0 right-0 -mr-12 pt-2">
                <button
                  type="button"
                  class="
                    ml-1
                    flex
                    items-center
                    justify-center
                    h-10
                    w-10
                    rounded-full
                    focus:outline-none
                    focus:ring-2
                    focus:ring-inset
                    focus:ring-white
                  "
                  @click="sidebarOpen = false"
                >
                  <span class="sr-only">Close sidebar</span>
                  <XIcon class="h-6 w-6 text-white" aria-hidden="true" />
                </button>
              </div>
            </TransitionChild>
            <div class="flex-1 h-0 pt-5 pb-4 overflow-y-auto">
              <div class="flex-shrink-0 flex items-center px-4">
                <img
                  class="h-8 w-auto"
                  src="@/assets/project403.png"
                  alt="project403"
                />
              </div>
              <nav class="mt-5 px-2 space-y-1">
                <template v-for="nav in navigation" :key="nav['route']">
                  <router-link
                    v-if="!nav['children']"
                    :to="{ name: nav['route'] }"
                    :class="[
                      'menu-nav',
                      {
                        'flex items-center justify-between':
                          nav['meta']['counterKey'],
                      },
                    ]"
                  >
                    {{ nav["name"] }}
                    <span
                      class="badge"
                      v-if="
                        nav['meta']['counterKey'] &&
                        menuCounter[nav['meta']['counterKey']]
                      "
                      >{{
                        parseBadgeCounter(
                          menuCounter[nav["meta"]["counterKey"]]
                        )
                      }}</span
                    >
                  </router-link>
                  <Disclosure
                    as="div"
                    v-else
                    class="space-y-1"
                    v-slot="{ open }"
                  >
                    <DisclosureButton class="menu-nav justify-between block">
                      {{ nav["name"] }}
                      <svg
                        :class="[
                          open ? 'text-gray-400 rotate-90' : 'text-gray-300',
                          'ml-3 flex-shrink-0 h-5 w-5 transform group-hover:text-gray-400 transition-colors ease-in-out duration-150',
                        ]"
                        viewBox="0 0 20 20"
                        aria-hidden="true"
                      >
                        <path d="M6 6L14 10L6 14V6Z" fill="currentColor" />
                      </svg>
                    </DisclosureButton>
                    <DisclosurePanel class="space-y-1">
                      <template
                        v-for="subMenu in nav['children']"
                        :key="subMenu.name"
                      >
                        <router-link
                          :to="{ name: subMenu['route'] }"
                          class="menu-nav menu-nav--sub"
                        >
                          {{ subMenu["name"] }}
                        </router-link>
                      </template>
                    </DisclosurePanel>
                  </Disclosure>
                </template>

                <button class="menu-nav w-full" @click="signOut">
                  Sign out
                </button>
              </nav>
            </div>
            <div class="flex-shrink-0 flex bg-gray-700 p-4">
              <router-link
                :to="{ name: 'profile/asset' }"
                class="flex-shrink-0 group block"
              >
                <div class="flex items-center">
                  <div>
                    <UserCircleIcon class="h-10 w-10 text-white" />
                  </div>
                  <div class="ml-3">
                    <p class="text-base font-medium text-white">
                      [{{ authUserGroup }}] {{ authUsername }}
                    </p>
                    <p
                      class="
                        text-sm
                        font-medium
                        text-gray-400
                        group-hover:text-gray-300
                      "
                    >
                      {{ authUserRole }}
                    </p>
                  </div>
                </div>
              </router-link>
            </div>
          </div>
        </TransitionChild>
        <div class="flex-shrink-0 w-14">
          <!-- Force sidebar to shrink to fit close icon -->
        </div>
      </Dialog>
    </TransitionRoot>

    <!-- Static sidebar for desktop -->
    <template v-if="isLoggedIn">
      <div class="hidden lg:flex lg:flex-shrink-0">
        <div class="flex flex-col w-64">
          <!-- Sidebar component, swap this element with another sidebar if you like -->
          <div class="flex flex-col h-0 flex-1">
            <div class="flex items-center h-16 flex-shrink-0 px-4 bg-gray-900">
              <img
                class="w-auto"
                src="@/assets/project403.png"
                alt="project403"
              />
            </div>
            <div class="flex-1 flex flex-col overflow-y-auto">
              <nav class="flex-1 px-2 py-4 bg-gray-800 space-y-1">
                <template v-for="nav in navigation" :key="nav['route']">
                  <router-link
                    v-if="!nav['children']"
                    :to="{ name: nav['route'] }"
                    :class="[
                      'menu-nav',
                      {
                        'flex items-center justify-between':
                          nav['meta']['counterKey'],
                      },
                    ]"
                  >
                    {{ nav["name"] }}
                    <span
                      class="badge"
                      v-if="
                        nav['meta']['counterKey'] &&
                        menuCounter[nav['meta']['counterKey']]
                      "
                      >{{
                        parseBadgeCounter(
                          menuCounter[nav["meta"]["counterKey"]]
                        )
                      }}
                    </span>
                  </router-link>
                  <Disclosure
                    as="div"
                    v-else
                    class="space-y-1"
                    v-slot="{ open }"
                  >
                    <DisclosureButton class="menu-nav justify-between block">
                      {{ nav["name"] }}
                      <svg
                        :class="[
                          open ? 'text-gray-400 rotate-90' : 'text-gray-300',
                          'ml-3 flex-shrink-0 h-5 w-5 transform group-hover:text-gray-400 transition-colors ease-in-out duration-150',
                        ]"
                        viewBox="0 0 20 20"
                        aria-hidden="true"
                      >
                        <path d="M6 6L14 10L6 14V6Z" fill="currentColor" />
                      </svg>
                    </DisclosureButton>
                    <DisclosurePanel class="space-y-1">
                      <template
                        v-for="subMenu in nav['children']"
                        :key="subMenu.name"
                      >
                        <router-link
                          :to="{ name: subMenu['route'] }"
                          class="menu-nav menu-nav--sub"
                        >
                          {{ subMenu["name"] }}
                        </router-link>
                      </template>
                    </DisclosurePanel>
                  </Disclosure>
                </template>

                <button class="menu-nav w-full" @click="signOut">
                  Sign out
                </button>
              </nav>
            </div>
            <div class="flex-shrink-0 flex bg-gray-700 p-4">
              <router-link
                :to="{ name: 'profile/asset' }"
                class="flex-shrink-0 w-full group block"
              >
                <div class="flex items-center">
                  <div>
                    <UserCircleIcon class="h-9 w-9 text-white" />
                  </div>
                  <div class="ml-3">
                    <p class="text-sm font-medium text-white">
                      [{{ authUserGroup }}] {{ authUsername }}
                    </p>
                    <p
                      class="
                        text-xs
                        font-medium
                        text-gray-300
                        group-hover:text-gray-200
                      "
                    >
                      {{ authUserRole }}
                    </p>
                  </div>
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </template>
    <div class="flex flex-col w-0 flex-1 overflow-hidden">
      <template v-if="isLoggedIn">
        <div
          class="
            relative
            z-10
            flex-shrink-0 flex
            h-16
            bg-white
            shadow
            lg:hidden
          "
        >
          <!-- border-r removed -->
          <button
            class="
              px-4
              border-gray-200
              text-gray-500
              focus:outline-none
              focus:ring-2
              focus:ring-inset
              focus:ring-indigo-500
              lg:hidden
            "
            @click="sidebarOpen = true"
          >
            <span class="sr-only">Open sidebar</span>
            <MenuAlt2Icon class="h-6 w-6" aria-hidden="true" />
          </button>
          <div class="flex-1 px-4 flex">
            <div class="flex-1 flex justify-end">
              <span
                class="
                  bg-gray-900
                  text-white text-sm
                  py-1
                  px-2
                  rounded-md
                  block
                  self-center
                  justify-end
                "
              >
                App Version: {{ `${serverVersion} (${appVersion})` }}
              </span>
              <form class="w-full flex md:ml-0 hidden" method="GET">
                <label for="search-field" class="sr-only">Search</label>
                <div
                  class="
                    relative
                    w-full
                    text-gray-400
                    focus-within:text-gray-600
                  "
                >
                  <div
                    class="
                      absolute
                      inset-y-0
                      left-0
                      flex
                      items-center
                      pointer-events-none
                    "
                  >
                    <SearchIcon class="h-5 w-5" aria-hidden="true" />
                  </div>
                  <input
                    id="search-field"
                    class="
                      block
                      w-full
                      h-full
                      pl-8
                      pr-3
                      py-2
                      border-transparent
                      text-gray-900
                      placeholder-gray-500
                      focus:outline-none
                      focus:placeholder-gray-400
                      focus:ring-0
                      focus:border-transparent
                      sm:text-sm
                    "
                    placeholder="Search"
                    type="search"
                    name="search"
                  />
                </div>
              </form>
            </div>
            <div class="ml-4 flex items-center md:ml-6">
              <!--            <button-->
              <!--              class="-->
              <!--                bg-white-->
              <!--                p-1-->
              <!--                rounded-full-->
              <!--                text-gray-400-->
              <!--                hover:text-gray-500-->
              <!--                focus:outline-none-->
              <!--                focus:ring-2-->
              <!--                focus:ring-offset-2-->
              <!--                focus:ring-indigo-500-->
              <!--              "-->
              <!--            >-->
              <!--              <span class="sr-only">View notifications</span>-->
              <!--              <BellIcon class="h-6 w-6" aria-hidden="true" />-->
              <!--            </button>-->

              <!-- Profile dropdown -->
              <!--              <Menu as="div" class="ml-3 relative">-->
              <!--                <div>-->
              <!--                  <MenuButton-->
              <!--                    class="-->
              <!--                      max-w-xs-->
              <!--                      bg-white-->
              <!--                      flex-->
              <!--                      items-center-->
              <!--                      text-sm-->
              <!--                      rounded-full-->
              <!--                      focus:outline-none-->
              <!--                      focus:ring-2-->
              <!--                      focus:ring-offset-2-->
              <!--                      focus:ring-indigo-500-->
              <!--                    "-->
              <!--                  >-->
              <!--                    <span class="sr-only">Open user menu</span>-->
              <!--                    <UserCircleIcon class="h-8 w-8" />-->
              <!--                  </MenuButton>-->
              <!--                </div>-->
              <!--                <transition-->
              <!--                  enter-active-class="transition ease-out duration-100"-->
              <!--                  enter-from-class="transform opacity-0 scale-95"-->
              <!--                  enter-to-class="transform opacity-100 scale-100"-->
              <!--                  leave-active-class="transition ease-in duration-75"-->
              <!--                  leave-from-class="transform opacity-100 scale-100"-->
              <!--                  leave-to-class="transform opacity-0 scale-95"-->
              <!--                >-->
              <!--                  <MenuItems-->
              <!--                    class="-->
              <!--                      origin-top-right-->
              <!--                      absolute-->
              <!--                      right-0-->
              <!--                      mt-2-->
              <!--                      w-48-->
              <!--                      rounded-md-->
              <!--                      shadow-lg-->
              <!--                      py-1-->
              <!--                      bg-white-->
              <!--                      ring-1 ring-black ring-opacity-5-->
              <!--                      focus:outline-none-->
              <!--                    "-->
              <!--                  >-->
              <!--                    <MenuItem-->
              <!--                      v-for="item in userNavigation"-->
              <!--                      :key="item.name"-->
              <!--                      v-slot="{ active }"-->
              <!--                    >-->
              <!--                      <a-->
              <!--                        :href="item.href"-->
              <!--                        :class="[-->
              <!--                          active ? 'bg-gray-100' : '',-->
              <!--                          'block px-4 py-2 text-sm text-gray-700',-->
              <!--                        ]"-->
              <!--                        >{{ item.name }}</a-->
              <!--                      >-->
              <!--                    </MenuItem>-->
              <!--                    <MenuItem>-->
              <!--                      <button-->
              <!--                        class="-->
              <!--                          block-->
              <!--                          px-4-->
              <!--                          py-2-->
              <!--                          text-sm text-gray-700-->
              <!--                          w-full-->
              <!--                          text-left-->
              <!--                          hover:bg-gray-100-->
              <!--                        "-->
              <!--                        type="button"-->
              <!--                        @click="signOut"-->
              <!--                      >-->
              <!--                        Sign out-->
              <!--                      </button>-->
              <!--                    </MenuItem>-->
              <!--                  </MenuItems>-->
              <!--                </transition>-->
              <!--              </Menu>-->
            </div>
          </div>
        </div>
      </template>
      <main
        :class="{
          'flex-1 relative overflow-y-auto focus:outline-none': isLoggedIn,
        }"
      >
        <div
          :class="[
            'justify-end pt-2 pr-2',
            {
              'bg-gray-50 flex justify-end': !isLoggedIn,
            },
            {
              'hidden lg:flex': isLoggedIn,
            },
          ]"
        >
          <span
            class="bg-gray-900 text-white text-sm py-1 px-2 rounded-md block"
          >
            App Version: {{ `${serverVersion} (${appVersion})` }}
          </span>
        </div>
        <div :class="{ 'py-6 px-4 sm:px-6 md:px-8': isLoggedIn }">
          <div
            v-show="invalidVersion"
            :class="[
              'bg-yellow-400 p-2 mb-4 w-full text-center',
              { 'absolute top-12 left-0 ': !isLoggedIn },
            ]"
          >
            <p class="text-black text-sm">
              <font-awesome-icon :icon="faExclamationTriangle" class="mr-1" />
              <span class="font-bold">You are not on the latest version!</span>

              Please refresh using Ctrl Shift R / ⌘ Shift R
            </p>
          </div>
          <router-view />
        </div>
      </main>
    </div>
  </div>
</template>
