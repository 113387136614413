import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import "tailwindcss/tailwind.css";
import "@/styles/app.scss";
import "vue-multiselect/dist/vue-multiselect.css";

// Validation
import { defineRule, configure } from "vee-validate";
import {
  required,
  min,
  max,
  numeric,
  length,
  alpha_num,
} from "@vee-validate/rules";

configure({
  generateMessage: (ctx) => {
    const { rule } = ctx;

    const param = rule.params ? rule.params : "";

    let errorMessage = "";

    switch (rule.name) {
      case "required":
        errorMessage = "This field is required";
        break;
      case "numeric":
        errorMessage = "This field must only contain digits";
        break;

      case "min":
        errorMessage = `This field must be at least ${param} characters`;
        break;

      case "max":
        errorMessage = `This field must be less than ${param} characters`;
        break;

      case "length":
        errorMessage = `This field must be exactly ${param} characters`;
        break;

      case "alpha_num":
        errorMessage = "This field must be alphanumeric";
        break;
    }

    return errorMessage;
  },
});

defineRule("required", required);
defineRule("min", min);
defineRule("max", max);
defineRule("numeric", numeric);
defineRule("length", length);
defineRule("alpha_num", alpha_num);
defineRule("telegram_id", (value) => {
  if (value) {
    if (value.match(/^[a-zA-Z0-9][\w*]*$/g)) {
      return true;
    } else {
      return "This field cannot start with underscore and must only contain letters, numbers and underscores";
    }
  } else {
    return true;
  }
});
defineRule("numeric_asterisk", (value) => {
  if (value) {
    if (value.match(/^[0-9*]*$/g)) {
      return true;
    } else {
      return "This field must only contain numbers";
    }
  } else {
    return true;
  }
});
defineRule("alpha_space", (value) => {
  if (value) {
    if (value.match(/^[a-zA-Z'". ]*$/g)) {
      return true;
    } else {
      return "This field must only contain letters";
    }
  } else {
    return true;
  }
});
defineRule("alpha_num_space", (value) => {
  if (value) {
    if (value.match(/^[\w ]*$/g)) {
      return true;
    } else {
      return "This field must be alphanumeric";
    }
  } else {
    return true;
  }
});
defineRule("confirmed", (value, [target]) => {
  if (value === target) {
    return true;
  }

  return "Password does not match.";
});

// Font awesome
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faSpinner,
  faTimesCircle,
  faHistory,
  faSync,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(faSpinner, faTimesCircle, faHistory, faSync);

const app = createApp(App);

app.component("font-awesome-icon", FontAwesomeIcon);

app.use(store).use(router);

app.mount("#app");
