import { createRouter, createWebHistory } from "vue-router";
import { userCan } from "@/use/permission";
import store from "@/store/index.js";
import crmRoutes from "@/router/crm";

const baseRoute = [
  {
    path: "/",
    name: "auth/login",
    meta: {
      requiresAuth: false,
    },
    component: () => import("../views/auth/Login.vue"),
  },
  {
    path: "/group",
    meta: {
      permission: "read.group",
      hasChild: true,
    },
    component: () =>
      import(/* webpackChunkName: "group" */ "../views/group/GroupBase.vue"),
    children: [
      {
        path: "",
        name: "group/list",
        meta: {
          permission: "read.group",
          menuName: "Group",
        },
        component: () =>
          import(
            /* webpackChunkName: "group" */ "../views/group/GroupList.vue"
          ),
      },
      {
        path: "/group/create",
        name: "group/create",
        meta: {
          permission: "create.group",
        },
        component: () =>
          import(
            /* webpackChunkName: "group" */ "../views/group/GroupForm.vue"
          ),
      },
      {
        path: "/group/:uuid",
        name: "group/show",
        meta: {
          permission: "update.group",
        },
        component: () =>
          import(
            /* webpackChunkName: "group" */ "../views/group/GroupForm.vue"
          ),
      },
    ],
  },
  {
    path: "/role",
    meta: {
      hasChild: true,
    },
    component: () =>
      import(/* webpackChunkName: "role" */ "../views/role/RoleBase.vue"),
    children: [
      {
        path: "",
        name: "role/list",
        meta: {
          menuName: "Role",
          permission: "read.role",
        },
        component: () =>
          import(/* webpackChunkName: "role" */ "../views/role/RoleList.vue"),
      },
      {
        path: "/role/create",
        name: "role/create",
        meta: {
          permission: "create.role",
        },
        component: () =>
          import(/* webpackChunkName: "role" */ "../views/role/RoleForm.vue"),
      },
      {
        path: "/role/:uuid",
        name: "role/show",
        meta: {
          permission: "update.role",
        },
        component: () =>
          import(/* webpackChunkName: "role" */ "../views/role/RoleForm.vue"),
      },
    ],
  },
  {
    path: "/asset",
    meta: {
      hasChild: true,
    },
    component: () =>
      import(/* webpackChunkName: "asset" */ "../views/asset/AssetBase.vue"),
    children: [
      {
        path: "",
        name: "asset/list",
        meta: {
          menuName: "Asset",
          permission: "read.asset",
        },
        component: () =>
          import(
            /* webpackChunkName: "asset" */ "../views/asset/AssetList.vue"
          ),
      },
      {
        path: "/asset/create",
        name: "asset/create",
        meta: {
          permission: "create.asset",
        },
        component: () =>
          import(
            /* webpackChunkName: "asset" */ "../views/asset/AssetForm.vue"
          ),
      },
      {
        path: "/asset/:uuid",
        name: "asset/show",
        meta: {
          permission: "update.asset",
        },
        component: () =>
          import(
            /* webpackChunkName: "asset" */ "../views/asset/AssetForm.vue"
          ),
      },
    ],
  },
  {
    path: "/profile",
    component: () =>
      import(
        /* webpackChunkName: "profile" */ "../views/profile/ProfileBase.vue"
      ),
    children: [
      {
        path: "",
        name: "profile/asset",
        component: () =>
          import(
            /* webpackChunkName: "profile" */ "../views/profile/ProfileAsset.vue"
          ),
      },
      {
        path: "/profile/password",
        name: "profile/password",
        component: () =>
          import(
            /* webpackChunkName: "profile" */ "../views/profile/ProfilePassword.vue"
          ),
      },
    ],
  },
  {
    path: "/user",
    meta: {
      hasChild: true,
    },
    component: () =>
      import(/* webpackChunkName: "user" */ "../views/user/UserBase.vue"),
    children: [
      {
        path: "",
        name: "user/list",
        meta: {
          menuName: "User",
          permission: "read.user",
        },
        component: () =>
          import(/* webpackChunkName: "user" */ "../views/user/UserList.vue"),
      },
      {
        path: "/user/create",
        name: "user/create",
        meta: {
          permission: "create.user",
        },
        component: () =>
          import(/* webpackChunkName: "user" */ "../views/user/UserForm.vue"),
      },
      {
        path: "/user/:uuid",
        name: "user/show",
        meta: {
          permission: "update.user",
        },
        component: () =>
          import(/* webpackChunkName: "user" */ "../views/user/UserForm.vue"),
      },
    ],
  },
  {
    path: "/bypass",
    meta: {
      hasChild: true,
    },
    component: () =>
      import(/* webpackChunkName: "bypass" */ "../views/bypass/BypassBase.vue"),
    children: [
      {
        path: "",
        name: "bypass/list",
        meta: {
          menuName: "Bypass",
          permission: "read.bypass",
        },
        component: () =>
          import(
            /* webpackChunkName: "bypass" */ "../views/bypass/BypassList.vue"
          ),
      },
      {
        path: "/bypass/create",
        name: "bypass/create",
        meta: {
          permission: "create.bypass",
        },
        component: () =>
          import(
            /* webpackChunkName: "bypass" */ "../views/bypass/BypassForm.vue"
          ),
      },
      {
        path: "/bypass/:uuid",
        name: "bypass/show",
        meta: {
          permission: "update.bypass",
        },
        component: () =>
          import(
            /* webpackChunkName: "bypass" */ "../views/bypass/BypassForm.vue"
          ),
      },
    ],
  },
  {
    path: "/error/401-unauthorized",
    name: "error/401",
    component: () =>
      import(
        /* webpackChunkName: "error" */ "../views/error/401Unauthorized.vue"
      ),
  },
  {
    path: "/error/404-not-found",
    name: "error/404",
    component: () =>
      import(/* webpackChunkName: "error" */ "../views/error/404NotFound.vue"),
  },
];

export const routes = baseRoute.concat(crmRoutes);

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const {
    meta: { permission, requiresAuth },
  } = to;

  const isLoggedIn = store.getters["auth/isLoggedIn"];

  if (requiresAuth !== false) {
    // If route requires auth
    if (isLoggedIn) {
      // If user is logged in and is trying to access login page
      if (to.name === "auth/login") {
        // Just redirect to profile page
        next({ name: "profile/asset" });
      } else {
        // Otherwise we check if user has the required permission
        if (userCan(permission) || !permission) {
          next();
        } else {
          next({ name: "error/401" });
        }
      }
    } else {
      // If user is not logged in, just redirect to login page
      next({ name: "auth/login" });
    }
  } else {
    // If route doesn't require auth
    if (isLoggedIn) {
      // If user is trying to access login page
      if (to.name === "auth/login") {
        // Just redirect to profile page
        next({ name: "profile/asset" });
      }
    } else {
      // Otherwise just take them there
      next();
    }
  }
});

export default router;
