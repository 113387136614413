import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";
import SecureLS from "secure-ls";
import auth from "@/store/modules/auth";
import versioning from "@/store/modules/versioning";

const ls = new SecureLS({ isCompression: false });

export default createStore({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    auth,
    versioning,
  },
  plugins: [
    createPersistedState({
      storage: {
        getItem: (key) => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: (key) => ls.remove(key),
      },
    }),
  ],
});
