import api from "@/services/api";
import { reactive } from "vue";

const menuCounter = reactive({
  callback: null,
  event: null,
  call: null,
});

export function useMenuCounter() {
  const updateMenuCounter = () => {
    api
      .get("/badge")
      .then((res) => {
        const {
          data: { result },
        } = res;

        Object.keys(result).forEach((badge) => {
          menuCounter[badge] = result[badge];
        });
      })
      .catch(() => {
        menuCounter.call = null;
        menuCounter.callback = null;
        menuCounter.event = null;
      });
  };

  return {
    updateMenuCounter,
    menuCounter,
  };
}
